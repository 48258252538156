import { CorpLink } from 'components/CorpLink';
import { CorporateButton } from 'components/CorporateButton';
import FadeInSection from 'components/FadeInSection';
import { Grid, GridRow, GridItem } from 'components/Grid';
import { Title } from 'components/Typography';
import { LeftSVG } from './LeftSVG';
import { FC } from 'react';
import { Link } from 'generated/graphql';
import { Text } from 'components/Typography';

export interface Banner2RightAlignedProps {
  id: string;
  title?: string;
  superText?: string;
  description?: string;
  link?: Link;
  desktopImage?: string;
  mobileImage?: string;
  imageAlignment: 'Left' | 'Right';
}

export const Banner2RightAligned: FC<Banner2RightAlignedProps> = ({
  imageAlignment,
  superText,
  id,
  desktopImage,
  mobileImage,
  title,
  description,
  link,
}) => {
  return (
    <>
      <div className="pt-[5%] hidden md:block">
        <Grid>
          <GridRow columns={16}>
            <GridItem colStart={1} colSpan={8}>
              <div
                className="flex-1 m-auto -ml-[15%] xl:-ml-[25%] 2xl:-ml-[35%]"
                data-test={[`${id}-left-image`]}
              >
                <FadeInSection delay="200ms">
                  <LeftSVG backgroundAsset={desktopImage || ''} className="min-w-[fit-content]" />
                </FadeInSection>
              </div>
            </GridItem>
            <GridItem colStart={10} colSpan={7}>
              <div className="flex h-full items-center">
                <div
                  className="pt-[30px] text-center flex-1 m-auto content-center px-[20px] md:pt-[10%] md:text-left md:px-[5%] md:top-[10%] llg:top-[unset] lg:top-[20%] lg:pt-0"
                  data-test={[`${id}-left-text`]}
                >
                  {superText && (
                    <FadeInSection delay="200ms">
                      <Text
                        className="mb-2 text-darkgray opacity-70 pt-0.5 text-center md:text-left"
                        data-test={[`${id}-left-super-text`]}
                        tag="p"
                        type="lead"
                      >
                        {superText}
                      </Text>
                    </FadeInSection>
                  )}
                  {title && (
                    <FadeInSection delay="400ms">
                      <Title
                        className="mb-2.5 sm:text-center md:text-left text-left text-2xl lg:text-6xl"
                        data-test={[`${id}-left-product-title`]}
                        tag="h3"
                        type="md"
                      >
                        {title}
                      </Title>
                    </FadeInSection>
                  )}
                  {description && (
                    <FadeInSection delay="600ms">
                      <Text data-test={[`${id}-left-description`]} tag="p" type="lg">
                        {description}
                      </Text>
                    </FadeInSection>
                  )}
                  {link?.url && (
                    <FadeInSection delay="800ms">
                      <CorpLink
                        className="-translate-y-[30px] text-[0.75rem] leading-[1rem] lg:text-[0.875rem] lg:leading-[1.25rem]"
                        link={link}
                        data-test=""
                        target={link.openInNewTab ? '_blank' : '_self'}
                      >
                        <span className="sr-only">{link?.displayName}</span>
                        <div
                          className="flex items-center justify-center mt-[1.85rem] md:justify-between"
                          data-test={[`${id}-left-button`]}
                        >
                          <div className="hidden md:block">
                            <CorporateButton
                              label={link?.displayName}
                              type="button"
                              buttonType="button"
                              color="primaryalternative"
                            />
                          </div>
                        </div>
                      </CorpLink>
                    </FadeInSection>
                  )}
                </div>
              </div>
            </GridItem>
          </GridRow>
        </Grid>
      </div>
    </>
  );
};
