import { useState, useEffect } from 'react';

const GetSafari = () => {
  const [isSafari, getIsSafari] = useState<boolean>(false);
  useEffect(() => {
    const IsSafari =
      window.navigator.userAgent.indexOf('Safari') > -1 &&
      window.navigator.userAgent.indexOf('Chrome') === -1;
    getIsSafari(IsSafari);
  }, []);
  return isSafari;
};

export default GetSafari;
