export const FullBleedMobileSVG = ({
  backgroundAsset,
  mobileBackgroundAsset,
}: {
  backgroundAsset: string;
  mobileBackgroundAsset: string;
}) => (
  <svg viewBox="0 0 320 614" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <clipPath id="banner2-mask4">
        <path
          d="M0 0L3.51906e-05 613.586C3.51906e-05 613.586 72.2221 598 159.333 598C246.444 598 320 613.586 320 613.586L320 3.06323e-05C320 3.06323e-05 246.444 16.4143 159.333 16.4143C72.252 16.4143 0.0494087 0.011227 0 0Z"
          fill="#FA85BD"
        />{' '}
      </clipPath>
    </defs>
    <image
      className="h-[700px] overflow-y-hidden"
      height="320"
      width="614"
      preserveAspectRatio="xMidYMid slice"
      xlinkHref={mobileBackgroundAsset || backgroundAsset}
      clipPath="url(#banner2-mask4)"
    />
  </svg>
);
