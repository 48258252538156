import React from 'react';

import { IBannerProps } from './types';
import GetSafari from '../../modules/getSafari';
import { Banner2FullBleed } from './components/Banner2FullBleed';
import { Banner2Desktop } from './components/Banner2Desktop';
import { Banner2LeftRight } from './components/Banner2LeftRight';

export const Banner2 = (
  {
    imageAlignment = 'Left',
    fullBleed = false,
    superText,
    title,
    description,
    link,
    backgroundAsset,
    mobileBackgroundAsset,
    ctaColor,
  }: IBannerProps,
  { id = 'banner2' },
) => {
  const textAlignment = imageAlignment === 'Left' ? 'Right' : 'Left';
  const isSafari = GetSafari();
  const desktopImage = () => {
    if (isSafari) {
      return backgroundAsset?.backgroundFallback;
    } else {
      return backgroundAsset?.background;
    }
  };
  const mobileImage = () => {
    if (isSafari) {
      return mobileBackgroundAsset?.backgroundFallback;
    } else {
      return mobileBackgroundAsset?.background;
    }
  };
  return (
    <div className="relative" data-test={[`${id}-container`]}>
      {(fullBleed && (
        <Banner2FullBleed
          id={id}
          title={title}
          superText={superText}
          description={description}
          link={link}
          desktopImage={desktopImage()}
          mobileImage={mobileImage()}
          imageAlignment={imageAlignment}
        />
      )) || (
        <div>
          <Banner2LeftRight
            id={id}
            title={title}
            superText={superText}
            description={description}
            link={link}
            desktopImage={desktopImage()}
            mobileImage={mobileImage()}
            imageAlignment={imageAlignment}
            textAlignment={textAlignment}
          />
          <Banner2Desktop
            id={id}
            title={title}
            superText={superText}
            description={description}
            link={link}
            desktopImage={desktopImage()}
            mobileImage={mobileImage()}
            imageAlignment={imageAlignment}
          />
        </div>
      )}
    </div>
  );
};
