export const LeftSVG = ({
  backgroundAsset,
  className = '',
}: {
  backgroundAsset: string;
  className: string;
}) => (
  <svg
    viewBox="0 0 710 600"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className={className}
  >
    <defs>
      <clipPath id="banner2-mask-left">
        <path
          opacity="0.8"
          d="M0 600L2.62268e-05 0C2.62268e-05 0 325 51.7605 717 51.7605C718 51.7605 719 51.7602 720 51.7595V547.978C329.313 548.241 1.14596e-08 599.738 1.14596e-08 599.738L0 600Z"
          fill="#FA85BD"
        />
      </clipPath>
    </defs>
    <image
      height="600"
      width="710"
      preserveAspectRatio="xMidYMid slice"
      clipPath="url(#banner2-mask-left)"
      xlinkHref={backgroundAsset}
    />
  </svg>
);
