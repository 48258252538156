export const LeftRightMobileSVG = ({
  backgroundAsset,
  mobileBackgroundAsset,
}: {
  backgroundAsset: string;
  mobileBackgroundAsset: string;
}) => (
  <svg viewBox="0 0 320 300" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <defs>
      <clipPath id="banner2-mask2">
        <path
          d="M0 0L3.51906e-05 283.392C3.51906e-05 283.392 72.2221 299.801 159.333 299.801C246.444 299.801 320 283.392 320 283.392L320 3.06226e-05C320 3.06226e-05 246.444 16.4091 159.333 16.4091C72.252 16.4091 0.0494087 0.0112235 0 0Z"
          fill="#FA85BD"
        />
      </clipPath>
    </defs>
    <image
      height="300"
      width="320"
      preserveAspectRatio="xMidYMid slice"
      xlinkHref={mobileBackgroundAsset || backgroundAsset}
      clipPath="url(#banner2-mask2)"
    />
  </svg>
);
