export const FullBleedSVG = ({ backgroundAsset }: { backgroundAsset: string }) => (
  <svg width="100%" viewBox="0 0 1440 600" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <clipPath id="banner2-mask3">
        <path
          d="M1440 0C1440 0 1115 46.8843 723 46.8843C331 46.8843 2.94946e-06 0.000123923 2.94946e-06 0.000123923L0 600C0 600 331 552.764 723 552.764C1115 552.764 1440 600 1440 600V0Z"
          fill="#FA85BD"
        />
      </clipPath>
    </defs>
    <image
      height="600"
      width="1440"
      preserveAspectRatio="xMidYMid slice"
      xlinkHref={backgroundAsset}
      clipPath="url(#banner2-mask3)"
    />
  </svg>
);
