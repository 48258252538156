import { CorpLink } from 'components/CorpLink';
import { CorporateButton } from 'components/CorporateButton';
import { Title } from 'components/Typography';
import link from 'next/link';
import { title } from 'process';
import { FC } from 'react';
import { LeftRightMobileSVG } from './LeftRightMobileSVG';
import { Text } from 'components/Typography';
import { Link } from 'generated/graphql';

export interface Banner2DesktopProps {
  id: string;
  title?: string;
  superText?: string;
  description?: string;
  link?: Link;
  desktopImage?: string;
  mobileImage?: string;
  imageAlignment: 'Left' | 'Right';
}

export const Banner2Desktop: FC<Banner2DesktopProps> = ({
  imageAlignment,
  superText,
  id,
  desktopImage,
  mobileImage,
  title,
  description,
  link,
}) => {
  return (
    <div className="block md:hidden">
      <div className="flex-1 m-auto" data-test={[`${id}-left-mobile-image`]}>
        <LeftRightMobileSVG
          backgroundAsset={desktopImage || ''}
          mobileBackgroundAsset={mobileImage || ''}
        />
      </div>
      <div
        className="pt-[30px] text-center flex-1 m-auto content-center px-[20px] md:pt-[10%] md:text-left md:px-[5%] md:top-[10%] lg:top-[20%] llg:top-[unset] llg:pt-0"
        data-test={[`${id}-left-mobile-text`]}
      >
        {superText && (
          <Text
            className="mb-2 text-darkgray opacity-70 pt-0.5 text-center md:text-left"
            data-test={[`${id}-left-mobile-super-text`]}
            tag="p"
            type="lead"
          >
            {superText}
          </Text>
        )}
        {title && (
          <Title
            className="mb-2.5 sm:text-center md:text-left text-left text-2xl lg:text-6xl"
            data-test={[`${id}-left-mobile-product-title`]}
            tag="h3"
            type="md"
          >
            {title}
          </Title>
        )}
        {description && (
          <Text data-test={[`${id}-left-mobile-description`]} tag="p" type="lg">
            {description}
          </Text>
        )}
        {link?.url && (
          <CorpLink
            className="-translate-y-[30px] text-[0.75rem] leading-[1rem] lg:text-[0.875rem] lg:leading-[1.25rem]"
            link={link}
            data-test={[`${id}-left-mobile-link`]}
            target={link.openInNewTab ? '_blank' : '_self'}
          >
            <span className="sr-only">{link?.displayName}</span>
            <div
              className="flex items-center justify-center mt-[1.85rem] md:justify-between"
              data-test={[`${id}-left-mobile-button`]}
            >
              <div className="block md:hidden">
                <CorporateButton
                  label={link?.displayName}
                  type="button"
                  buttonType="button"
                  color="primaryalternative"
                />
              </div>
            </div>
          </CorpLink>
        )}
      </div>
    </div>
  );
};
