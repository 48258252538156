export const RightSVG = ({ backgroundAsset }: { backgroundAsset: string }) => (
  <svg viewBox="0 0 710 600" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <defs>
      <clipPath id="banner2-mask-right">
        <path
          opacity="0.8"
          d="M710 600L710 -3.14722e-05C710 -3.14722e-05 389.514 51.7605 2.9583 51.7605C1.97182 51.7605 0.985713 51.7602 2.36109e-05 51.7595L2.36202e-05 547.978C385.261 548.241 710 599.738 710 599.738L710 600Z"
          fill="#FA85BD"
        />
      </clipPath>
    </defs>
    <image
      height="600"
      width="710"
      preserveAspectRatio="xMidYMid slice"
      xlinkHref={backgroundAsset}
      clipPath="url(#banner2-mask-right)"
    />
  </svg>
);
