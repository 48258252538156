import { FC } from 'react';
import { Banner2LeftAligned } from './Banner2LeftAligned';
import { Banner2RightAligned } from './Banner2RightAligned';
import { Link } from 'generated/graphql';

export interface Banner2LeftRightProps {
  id: string;
  title?: string;
  superText?: string;
  description?: string;
  link?: Link;
  desktopImage?: string;
  mobileImage?: string;
  imageAlignment: 'Left' | 'Right';
  textAlignment: 'Left' | 'Right';
}

export const Banner2LeftRight: FC<Banner2LeftRightProps> = ({
  imageAlignment,
  superText,
  id,
  desktopImage,
  mobileImage,
  title,
  description,
  link,
  textAlignment,
}) => {
  if (textAlignment === 'Right') {
    return (
      <Banner2RightAligned
        id={id}
        title={title}
        superText={superText}
        description={description}
        link={link}
        desktopImage={desktopImage}
        mobileImage={mobileImage}
        imageAlignment={imageAlignment}
      />
    );
  }

  if (textAlignment === 'Left') {
    return (
      <Banner2LeftAligned
        id={id}
        title={title}
        superText={superText}
        description={description}
        link={link}
        desktopImage={desktopImage}
        mobileImage={mobileImage}
        imageAlignment={imageAlignment}
      />
    );
  }

  return null;
};
