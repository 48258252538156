import clsx from 'clsx';
import { CorpLink } from 'components/CorpLink';
import { CorporateButton } from 'components/CorporateButton';
import FadeInSection from 'components/FadeInSection';
import { Title } from 'components/Typography';
import { FullBleedMobileSVG } from './FullBleedMobileSVG';
import { FullBleedSVG } from './FullBleedSVG';
import { FC } from 'react';
import { Text } from 'components/Typography';
import { Link } from 'generated/graphql';

export interface Banner2FullBleedProps {
  id: string;
  title?: string;
  superText?: string;
  description?: string;
  link?: Link;
  desktopImage?: string;
  mobileImage?: string;
  imageAlignment: 'Left' | 'Right';
}

export const Banner2FullBleed: FC<Banner2FullBleedProps> = ({
  imageAlignment,
  superText,
  id,
  desktopImage,
  mobileImage,
  title,
  description,
  link,
}) => {
  return (
    <div>
      <div className="block text-center md:hidden md:text-left">
        <div
          className={clsx('h-full flex overflow-hidden items-center md:relative', {
            'justify-end': imageAlignment === 'Right',
            'justify-start': imageAlignment !== 'Right',
          })}
        >
          <div
            className="absolute z-[1] text-center p-[5%] w-full bottom-0 md:text-left md:w-[50%] md:top-[10%] lg:top-[15%] llg:top-[unset] md:bottom-[unset]"
            data-test={[`${id}-full-bleed-text`]}
          >
            {superText && (
              <FadeInSection delay="200ms">
                <Text
                  className="mb-2 text-darkgray opacity-70 pt-0.5 text-center md:text-left"
                  data-test={[`${id}-full-bleed-super-text`]}
                  tag="p"
                  type="lead"
                >
                  {superText}
                </Text>
              </FadeInSection>
            )}
            {title && (
              <FadeInSection delay="400ms">
                <Title
                  className="mb-2.5 sm:text-center md:text-left text-left text-2xl lg:text-6xl"
                  data-test={[`${id}-full-bleed-product-title`]}
                  tag="h2"
                  type="md"
                >
                  {title}
                </Title>
              </FadeInSection>
            )}
            {description && (
              <FadeInSection delay="600ms">
                <Text data-test={[`${id}-full-bleed-description`]} tag="p" type="lg">
                  {description}
                </Text>
              </FadeInSection>
            )}
            {link?.url && (
              <FadeInSection delay="800ms">
                <CorpLink
                  className="-translate-y-[30px] text-[0.75rem] leading-[1rem] lg:text-[0.875rem] lg:leading-[1.25rem]"
                  data-test={[`${id}-full-bleed-link`]}
                  link={link}
                  target={link.openInNewTab ? '_blank' : '_self'}
                >
                  <span className="sr-only">{link?.displayName}</span>
                  <div
                    className="flex items-center justify-center mt-[1.85rem] md:justify-between"
                    data-test={[`${id}-full-bleed-button`]}
                  >
                    <div className="hidden md:block">
                      <CorporateButton
                        label={link?.displayName}
                        type="button"
                        buttonType="button"
                        color="primaryalternative"
                      />
                    </div>
                  </div>
                </CorpLink>
              </FadeInSection>
            )}
          </div>

          <div className="w-full overflow-hidden py-[50px]" data-test={[`${id}-full-bleed-image`]}>
            <FadeInSection delay="200ms">
              <FullBleedSVG backgroundAsset={desktopImage || ''} />
            </FadeInSection>
          </div>
        </div>
      </div>

      <div className="block text-center md:hidden md:text-left">
        <div
          className="w-full overflow-hidden py-[50px]"
          data-test={[`${id}-full-bleed-mobile-image`]}
        >
          <FullBleedMobileSVG
            backgroundAsset={desktopImage || ''}
            mobileBackgroundAsset={mobileImage || ''}
          />
        </div>
        <div
          className={clsx('h-full flex overflow-hidden items-center md:relative', {
            'justify-end': imageAlignment === 'Right',
            'justify-start': imageAlignment !== 'Right',
          })}
        >
          <div
            className="absolute z-[1] text-center p-[5%] w-full bottom-0 md:text-left md:w-[50%] md:top-[10%] lg:top-[15%] md:bottom-[unset]"
            data-test={[`${id}-full-bleed-mobile-text`]}
          >
            {superText && (
              <Text
                className="mb-2 text-darkgray opacity-70 pt-0.5 text-center md:text-left"
                data-test={[`${id}-full-bleed-mobile-super-text`]}
                tag="p"
                type="lead"
              >
                {superText}
              </Text>
            )}
            {title && (
              <Title
                className="mb-2.5 sm:text-center md:text-left text-left text-2xl lg:text-6xl"
                data-test={[`${id}-full-bleed-mobile-product-title`]}
                tag="h3"
                type="md"
              >
                {title}
              </Title>
            )}
            {description && (
              <Text data-test={[`${id}-full-bleed-mobile-description`]} tag="p" type="lg">
                {description}
              </Text>
            )}
            {link?.url && (
              <CorpLink
                className="-translate-y-[30px] text-[0.75rem] leading-[1rem] lg:text-[0.875rem] lg:leading-[1.25rem]"
                data-test={[`${id}-full-bleed-mobile-link`]}
                target={link.openInNewTab ? '_blank' : '_self'}
                link={link}
              >
                <span className="sr-only">{link?.displayName}</span>
                <div
                  className="flex items-center justify-center mt-[1.85rem] md:justify-between"
                  data-test={[`${id}-full-bleed-mobile-button`]}
                >
                  <div className="block md:hidden">
                    <CorporateButton
                      label={link?.displayName}
                      type="button"
                      buttonType="button"
                      color="primaryalternative"
                    />
                  </div>
                </div>
              </CorpLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
